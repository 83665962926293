// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-emblemsandmotifs-jsx": () => import("./../../../src/pages/emblemsandmotifs.jsx" /* webpackChunkName: "component---src-pages-emblemsandmotifs-jsx" */),
  "component---src-pages-general-jsx": () => import("./../../../src/pages/general.jsx" /* webpackChunkName: "component---src-pages-general-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-shapesandsizes-jsx": () => import("./../../../src/pages/shapesandsizes.jsx" /* webpackChunkName: "component---src-pages-shapesandsizes-jsx" */),
  "component---src-pages-signtypes-jsx": () => import("./../../../src/pages/signtypes.jsx" /* webpackChunkName: "component---src-pages-signtypes-jsx" */)
}

